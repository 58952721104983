import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/Broadcast/layout/Layout.vue';

export default [
  {
    path: '/broadcast',
    component: LAYOUT.base,
    redirect: '/broadcast/topics',
    children: [
      {
        path: 'topics',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastTopics',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-topics' */ '@/modules/Broadcast/views/TopicsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'templates',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastTemplates',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-templates' */ '@/modules/Broadcast/views/TemplatesTab.vue'
              ),
          },
        ],
      },
      {
        path: 'broadcast',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastBroadcast',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-broadcast' */ '@/modules/Broadcast/views/BroadcastTab.vue'
              ),
          },
        ],
      },
      {
        path: 'logs',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastLogs',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-logs' */ '@/modules/Broadcast/views/BroadcastLogsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'autobroadcast',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastAutobroadcast',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-autobroadcast' */ '@/modules/Broadcast/views/AutobroadcastTab.vue'
              ),
          },
        ],
      },
      {
        path: 'bot_logs',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastBotLogs',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-bot-logs' */ '@/modules/Broadcast/views/BotLogs.vue'
              ),
          },
        ],
      },
      {
        path: 'settings',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BroadcastSettings',
            component: () =>
              import(
                /* webpackChunkName: 'broadcast-bot-logs' */ '@/modules/Broadcast/views/SettingsTab.vue'
              ),
          },
        ],
      },
    ],
  },
];
