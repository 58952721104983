<template>
  <v-container>

    <!--SELECT MODEL-->
    <v-row>
      <v-col>
       <v-select
              v-model="selectedTable"
              :items="[{id:-1,name:$t('modules.customdata.model.new')}].concat(custom_data_tables_select)"
              item-text="name"
              item-value="id"
              :placeholder="$t('modules.customdata.select_model')"
              :hint="$t('modules.customdata.select_model')"
              persistent-hint
              outlined
              hide-details
              dense
              background-color="white"
              class="mb-5 elevation-1"
       >
       </v-select>
        </v-col>
    </v-row>

    <div class="mb-5">
      <v-card class="pt-0">
         <v-form ref="form" v-model="valid">
        <v-container>
        <v-row>
          <v-col>
            <v-text-field
              :label="$t('modules.customdata.model.name')"
              v-model="name"
              :rules="nameRules"
              :disabled="selectedTable && selectedTable!==-1"
              :hint="
                $t('modules.customdata.model.name')
                + $t('modules.customdata.model.not_editable_after_creation')
              "
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field :label="$t('modules.customdata.model.description')"
                          v-model="description"
                           :rules="descRules"
            ></v-text-field>
          </v-col>
        </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-checkbox
                class="mt-0"
                v-model="activateRegUserId"
                @change="changeRegUserId"
                :label="$t('modules.customdata.model.add_reg_user_id_label')"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>

            <p>{{$t('modules.customdata.model.json')}}</p>
            <VJsoneditor v-model="json_obj_in_editor" :options="options" ></VJsoneditor>
              </v-col>
            </v-row>
             <v-row>
               <v-col>
            <p>{{$t('modules.customdata.model.json_example')}}</p>
            <pre class="json-example">
  {
    "minimal":{"type":"INTEGER"},
    "column_name": {
      "type":"STRING", //Required, can be also "INTEGER" | "DATE" | "BOOLEAN" | "FLOAT" | "TEXT
      "default":"default value", //optional
      "unique":"true"|"false", //optional
      "label":"custom name", //optional
    }
  }</pre>
                 </v-col>


      </v-row>

    <v-divider class="my-5" />
    
    <v-row>
      <v-col>
          <p>{{$t('modules.customdata.model.json_statistics')}}</p>
          <VJsoneditor v-model="statistics_json_in_editor" :options="options" ></VJsoneditor>
      </v-col>
    </v-row>


        </v-container>
         </v-form>
          </v-card>
    </div>


    <div style="text-align: center">

      <v-btn
          :disabled="!(json_obj && valid)"
          color="primary"
          elevation="23"
          @click="createModel"
      >{{ $t('modules.customdata.create') }}</v-btn>

       <v-btn v-if="selectedTable && selectedTable!==-1"
               elevation="23"
              color="error" class="ma-2 white--text" @click="deleteModel"
                >{{ $t('modules.customdata.delete') }}
                  <v-icon dark>mdi-trash-can</v-icon>
                </v-btn>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VJsoneditor from 'v-jsoneditor'
import i18n from '@/lang/i18n';

export default {
  name: "CreateTab",

   components: {
            VJsoneditor
        },

  data: () => ({

    options:{ mode: 'code' },
    name:"",
    description:"",
    valid: false,
    selectedTable:null,
    tables:[],
    table:{},
    json_obj: {},
    statistics_json: {},
     nameRules: [
        v => !!v || i18n.t('modules.customdata.model.name_required'),
        v => /^[A-Za-z0-9_]*$/.test(v) || i18n.t('modules.customdata.model.name_required')
      ],

     descRules: [
        v => !!v || i18n.t('modules.customdata.model.description_required'),
        v => v.length>10 || i18n.t('modules.customdata.model.description_required')
      ],

    activateRegUserId: false,
  }),

  async mounted() {
    await this.CustomDataAxiosGetTables();
    this.tables = this.custom_data_tables
  },

  watch: {
    selectedTable(t) {
      let tt_obj=null
      for(let tt of this.tables){
        if(tt.id === t){
          tt_obj=tt
          break;
        }
      }


      if(tt_obj!==null){
        this.name = tt_obj.name
        this.description = tt_obj.description
        this.json_obj = tt_obj.columns_json
        this.statistics_json = tt_obj?.statistics_json
        this.activateRegUserId = !!this.json_obj?.reg_user_id;

      }else{
          this.name = ""
        this.description =""
        this.json_obj = {}
        this.statistics_json = {}
        this.activateRegUserId = false
      }

    },

    json_obj: function(obj) {
      this.activateRegUserId = !!obj?.reg_user_id
    },
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'custom_data_tables_select',
      'custom_data_tables',
      'user',
    ]),

    json_obj_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.json_obj);
      },
      set(value) {
        this.json_obj = value
      },
    },

    statistics_json_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.statistics_json);
      },
      set(value) {
        this.statistics_json = value
      },
    },
  },

  methods: {
    ...mapActions(['CustomDataAxiosGetTables',
      'CustomDataAxiosCreateModel',
      'CustomDataAxiosDeleteModel']),

    async createModel(){
        if ( !this.json_obj?.reg_user_id && this.activateRegUserId) {
          this.json_obj['reg_user_id'] = {"type":"INTEGER", "unique": "true"}
        }

       await this.CustomDataAxiosCreateModel({
        name: this.name,
        description:this.description,
        columns_json:this.json_obj,
        statistics_json:this.statistics_json
      });
        await this.CustomDataAxiosGetTables();
         this.tables = this.custom_data_tables

    },


    async deleteModel(){

       if (confirm(i18n.t('modules.customdata.model.confirm_delete'))) {
         await this.CustomDataAxiosDeleteModel({name: this.name})
         await this.CustomDataAxiosGetTables();
         this.tables = this.custom_data_tables

         this.name = ""
         this.description = ""
         this.json_obj = {}
         this.statistics_json = {}
         this.activateRegUserId = false
       }

    },

    changeRegUserId(val) {
      if (val && !this.json_obj?.reg_user_id) {
        this.json_obj['reg_user_id'] = {"type":"INTEGER", "unique": "true"}
      } else {
        delete this.json_obj['reg_user_id']
      }
    }


  },
};
</script>


<style lang="scss">
.json-example {
  font-size: 12px;
  color:#050038;
  background-color: #FFFBD1;
  padding: 20px;
}
</style>
