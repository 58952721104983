import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/FastLine/layout/Layout.vue';

export default [
  {
    path: '/fastline',
    component: LAYOUT.base,
    redirect: '/fastline/section',
    children: [
      {
        path: 'section',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'FastLineSection',
            component: () => import(/* webpackChunkName: 'file-manager-files' */ '@/modules/FastLine/views/FastLine.vue'),
          },
        ],
      },
    ],
  },
];
