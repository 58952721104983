const state = {
  broadcast: {
    defaultLang: '',
    currentLang: '',
    defaulTemplateData: {
      id: '',
      templateName: '',
      showBack: false,
      alias: '',
      topic_id: null,
      values: {},
      create: true,
      Markdown: false,
      MarkdownV2: false,
    },
    initTemplateData: {
      id: '',
      templateName: '',
      showBack: false,
      alias: '',
      topic_id: null,
      values: {},
      create: true,
      Markdown: false,
      MarkdownV2: false,
      // parse_mode: 'html',
      // parse_mode: 'Markdown',
      // parse_mode: 'MarkdownV2',
    },
  },  
};

const mutations = {
  SET_DEFAULT_LANG (state, value) {
    state.broadcast.defaultLang = value;
    state.broadcast.currentLang = value;
  },
  SET_LANGS_VALUES_DEFAULT (state, langs) {
    state.broadcast.defaulTemplateData.values = langs;
  },
  SET_LANGS_VALUES_INIT (state, langs) {     
    state.broadcast.initTemplateData.values = langs;
  },
  SET_CURRENT_LANG (state, lang) {
    state.broadcast.currentLang = lang;    
  },
  SET_VALUE_KEY (state, params) {
    let currentLang, type, value;   
    [currentLang, type, value] = [...params];

    state.broadcast.initTemplateData.values[currentLang][type] = value;
  },
  SET_TEMPLATE_NAME (state, value) {
    state.broadcast.initTemplateData.templateName = value;
  },
  SET_TEMPLATE_ALIAS (state, value) {
    state.broadcast.initTemplateData.alias = value;
  },
  SET_TEMPLATE_THEME (state, value) {
    state.broadcast.initTemplateData.topic_id = value;
  },
  SET_TEMPLATE_ID (state, value) {
    state.broadcast.initTemplateData.id = value;
  },
  SET_INIT_TEMPLATE_DATA (state, values) {
    state.broadcast.initTemplateData = values;
  },
  SET_TEMPLATE_CREATE(state, value) {
    state.broadcast.initTemplateData.create = value
  },
  SET_TEMPLATE_MARKDOWN(state, value) {
    state.broadcast.initTemplateData.Markdown = value
  },
  SET_TEMPLATE_MARKDOWNV_TWO(state, value) {
    state.broadcast.initTemplateData.MarkdownV2 = value
  },
};

const actions = {
  set_default_lang ({ commit, state }) {
    let langs = state.languages;				
    let langObj = langs.filter( (lang) => lang['default'] )
    
    commit('SET_DEFAULT_LANG', langObj[0].code)
  },
  set_default_lang_values ({ commit, state }) {    
    let obj = {}

    for(let lang of state.languages) {
      obj[lang.code] = {
        message: '', 
        fileLink: '', 
        fileBody: null,
      }
    }

		commit('SET_LANGS_VALUES_DEFAULT', obj)
  },
  set_init_lang_values ({ commit, state }) {    
    let obj = {}

    for(let lang of state.languages) {
      obj[lang.code] = {
        message: '', 
        fileLink: '', 
        fileBody: null,
      }
    }

		commit('SET_LANGS_VALUES_INIT', obj)
  },
  set_value_key ({ commit }, params) {
    commit('SET_VALUE_KEY', params)
  },
  set_current_lang ({ commit }, value) {
    commit('SET_CURRENT_LANG', value)
  },
  set_template_name ({ commit }, value) {
    commit('SET_TEMPLATE_NAME', value)
  },
  set_template_alias ({ commit }, value) {
    commit('SET_TEMPLATE_ALIAS', value)
  },
  set_template_theme ({ commit }, value) {
    commit('SET_TEMPLATE_THEME', value)
  },
  set_template_id ({ commit }, value) {
    commit('SET_TEMPLATE_ID', value)
  },
  set_init_template_data({ commit }, values) {
    commit('SET_INIT_TEMPLATE_DATA', values)
  },
  set_template_create({ commit }, value) {
    commit('SET_TEMPLATE_CREATE', value)
  },
  set_template_markdown ({ commit }, value) {
    commit('SET_TEMPLATE_MARKDOWN', value)
    if(!value) commit('SET_TEMPLATE_MARKDOWNV_TWO', value)
  },
  set_template_markdownv_two ({ commit }, value) {
    commit('SET_TEMPLATE_MARKDOWNV_TWO', value)
  },
};

const getters = {
  default_lang: (state) => {    
    return state.broadcast.defaultLang;
  },
  values_langs: (state) => {
    return state.broadcast.initTemplateData.values;
  },
  current_lang: (state) => {
    return state.broadcast.currentLang;
  },
  default_template_data: (state) => {
    return state.broadcast.defaulTemplateData;
  },
  init_template_data: (state) => {
    return state.broadcast.initTemplateData;
  },
  template_name: (state) => {
    return state.broadcast.initTemplateData.templateName;
  },
  template_alias: (state) => {
    return state.broadcast.initTemplateData.alias;
  },
  template_theme: (state) => {
    return state.broadcast.initTemplateData.topic_id;
  },
  template_id: (state) => {
    return state.broadcast.initTemplateData.id;
  },
  template_markdown: (state) => state.broadcast.initTemplateData.Markdown,
  template_markdownv_two: (state) => state.broadcast.initTemplateData.MarkdownV2,
};

export default {
  state,
  mutations,
  actions,
  getters,
};